<template>
  <div class="planning pb-8 bg-white mb-12">
    <div class="container mx-auto max-w-6xl flex items-center pl-4 mb-4">
      <DoiHeader :level="2" :fontFamily="0" class="header-font"
        >JE RÉSERVE UN CRÉNEAU</DoiHeader
      >
    </div>
    <!-- <template v-if="responsive === false">
      <DoiForeground v-show="isLoading">
        <DoiSpinner :spinnerSize="1" :isAbsolute="true" />
      </DoiForeground>
      <div class="container mx-auto max-w-6xl">
        <section v-if="isDisplay">
          <FullCalendarAsync @eventDidMount="handleEvents" @eventMouseEnter="handleMouseEnter" @eventMouseLeave="handleMouseLeave" @dayHeaderDidMount="handleDayHeader" @eventClick="handleSelect" :events="events" :slotDuration="slotsSize" />
        </section>
        <div v-if="!isLoading && events.length === 0" class="flex text-center justify-center">
          <DoiText :fontFamily="0" :textColor="0" :fontSize="4">AUCUN CRÉNEAU DISPONIBLE POUR CE SPORT</DoiText>
        </div>
      </div>
    </template> -->
    <div class="container mx-auto max-w-6xl">
      <PlanningLight
        @clickTimeslot="addToCart"
        :isLoading="isLoading"
        @onReload="getSlots()"
        :slots="slots"
        :nbPersonneConseille="nbPersonneConseille"
      />
    </div>
    <div class="mt-8 flex justify-center text-center px-6 sm:px-0">
      <DoiText :textColor="3" :fontFamily="4"
        >Les prix affichés sur le planning correspondent à des prix par créneau
        et par joueur sur la base de
        <DoiText type="span" :textColor="3" :fontFamily="3">{{
          nbPersonneConseille
        }}</DoiText>
        joueur(s) pour le sport
        <DoiText type="span" :textColor="3" :fontFamily="3"
          >{{ getSportLibelle }}.</DoiText
        ></DoiText
      >
    </div>
    <AuthModal
      @close="showModalConnection = false"
      :open="showModalConnection"
    />
  </div>
</template>

<script>
import PlanningLight from "@/views/fitarena/PlanningLight";
import AuthModal from "@/views/auth/AuthModal";

import dayjs from "dayjs";
import { mapActions } from "vuex";

export default {
  name: "Planning",
  data() {
    return {
      events: [],
      responsive: false,
      slots: [],
      openCartRecap: false,
      showModalConnection: false,
      currentDate: 0,
    };
  },
  props: {
    onReload: {
      type: Number,
      default: 0,
    },
  },
  components: {
    // FullCalendarAsync: () => ({
    //   // Le composant à charger (doit être une `Promise`)
    //   component: import(/* webpackChunkName: "calendar" */ '@/components/organisms/FullCalendarAsync'),
    //   // Un composant à utiliser pendant que le composant asynchrone se charge
    //   loading: DoiSpinner,
    //   // Un composant d'erreur à utiliser au cas où le chargement échoue
    //   error: DoiSpinner,
    //   // Le délai à patienter avant d'afficher le composant de chargement. Par défaut : 200ms.
    //   delay: 200,
    //   // Le composant d'erreur sera affiché si un délai de timeout est fourni et dépassé.
    //   // Par défaut: délai infini.
    //   timeout: 10000
    // }),
    PlanningLight,
    AuthModal,
  },
  async mounted() {
    this.getScreenSize();
    this.currentDate = dayjs().format();
    await this.getSlots();
  },
  computed: {
    slotsSize() {
      const timeSlotSize = this.timeslots[1];
      let timeSlot = "00:30:00";
      const begin = dayjs(timeSlotSize.dtDateHeureDebut).unix();
      const end = dayjs(timeSlotSize.dtDateHeureFin).unix();
      if (end - begin <= 900) timeSlot = "00:10:00";
      return timeSlot;
    },
    isDisplay() {
      return this.events.length > 0;
    },
    isLoading() {
      return this.isCartLoading || this.isTimeslotsLoading;
    },
    isTimeslotsLoading() {
      return this.$store.getters["TimeslotsModule/getTimeslotsIsFetched"];
    },
    isCartLoading() {
      return this.$store.getters["CartModule/isCartLoading"];
    },
    timeslots() {
      return this.$store.getters["TimeslotsModule/getTimeslots"];
    },
    getCart() {
      return this.$store.getters["CartModule/get_cart"];
    },
    fitArena() {
      return this.$store.getters["FitArenaModule/fitArena"];
    },
    idFitArena() {
      return this.$store.getters["FitArenaModule/idFitArena"];
    },
    getIdSport() {
      return this.$store.getters["FitArenaModule/get_id_sport"];
    },
    getSportLibelle() {
      return this.$store.getters["FitArenaModule/get_sport_libelle"];
    },
    nbPersonneConseille() {
      const selectedSport = this.$store.getters["FitArenaModule/selectedSport"];
      return selectedSport ? selectedSport.nbPersonneConseille + "" : "";
    },
  },
  methods: {
    ...mapActions("TimeslotsModule", ["retrieveTimeslots2"]),
    getScreenSize() {
      if (window.innerWidth >= 768) this.responsive = false;
      else this.responsive = true;
    },
    addToCart(props) {
      const idCreneau = props.nIdCreneau;
      const userId = this.$store.getters["UserModule/get_user_id"];

      if (userId === null) this.showModalConnection = true;
      else if (this.getCart) {
        const slotFoundInCart = this.getCart.contenu.some(
          (cartData) => parseInt(cartData.nIdCreneau) === parseInt(idCreneau)
        );

        if (!slotFoundInCart) {
          const slotData = this.timeslots.find((item) => {
            return parseInt(item.nIdCreneau) === parseInt(idCreneau);
          });
          if (slotData && slotData.sStatut === "disponible") {
            slotData.sStatut = "reserve";
            this.$store.dispatch("CartModule/ADD_TO_CART2", {
              data: slotData,
              idSport: this.getIdSport,
            });
          }
        }
      }
    },
    async handleSelect(info) {
      try {
        this.addToCart(info.event.extendedProps);
        info.el.classList.add("hover");
        info.el.classList.add("selected");
        info.el
          .getElementsByClassName("price-field")[0]
          .classList.add("hidden");
        info.el.getElementsByClassName("date-field")[0].classList.add("hidden");
      } catch (err) {}
    },
    async getSlots() {
      if (this.fitArena.idFitArena === null) return;
      setTimeout(() => {
        this.retrieveTimeslots2({
          debut: this.currentDate,
          fitArena: this.fitArena.idFitArena,
          duree: this.fitArena.nbJoursVisiblesReservationCreneaux ?? 7,
          idSport: this.getIdSport,
        });
      }, 1500);
    },
    updateUiSlots() {
      const contactId = this.$store.getters["UserModule/get_contact_id"];

      this.events = [];
      let slots = [];
      this.timeslots.forEach((item) => {
        // SI LE DÉBUT DU CRÉNEAU EST SUPÉRIEUR OU ÉGAL À LA DATE ACTUELLE (MASQUAGE DES CRÉNEAUX DANS LE PASSÉ) ==> TEMPORAIRE
        if (dayjs(item.dtDateHeureDebut).unix() >= dayjs().unix()) {
          let title = "DISPONIBLE";
          let statut = "disponible";
          if (item.sStatut !== "disponible") {
            this.getCart.contenu.find((itemCart) => {
              return itemCart.nIdCreneau === item.nIdCreneau;
            });

            if (item.sStatut === "panier") {
              title = "PANIER";
              statut = "panier";
            } else {
              title = "RÉSERVÉ";
              statut = "reserve";
            }
          }
          const slotItem = {
            id: item.nIdCreneau,
            title: title,
            start: item.dtDateHeureDebut,
            end: item.dtDateHeureFin,
            price: item.fTarif,
            tarifIndividuel: item.tarifIndividuel,
            statut: statut,
            nIdCreneau: item.nIdCreneau,
            nIdContact: contactId,
            nIdSport: this.getIdSport,
            selected: false,
            prixBarre: item.prixBarre,
            dureeCreneau: item.dureeCreneau,
            typeCreneau: item.typeCreneau,
            remplissage: item.remplissage,
            nbParticipantMax: item.nbParticipantMax,
            nbPersonneConseille: item.nbPersonneConseille,
          };
          this.events.push(slotItem);

          slots = [...slots, slotItem];
        }
      });
      this.slots = slots;
      const element = document.getElementsByClassName("fc-timegrid-col");
      const currentDate = dayjs().format("YYYY-MM-DD");
      const currentDateObj = dayjs(currentDate);

      element.forEach((element) => {
        const attr = element.getAttribute("data-date");
        const planningDate = dayjs(attr).format("YYYY-MM-DD");
        const planningDateObj = dayjs(new Date(planningDate));

        if (isNaN(currentDateObj.diff(planningDateObj)) === false) {
          if (planningDateObj.diff(currentDateObj) < 0) {
            element
              .getElementsByClassName("fc-timegrid-col-frame")[0]
              .classList.add("bg-fa-middle-gray");
          }
        }
      });
    },
    handleEvents(arg) {
      arg.el.style.borderColor = "transparent";
      arg.el.style.borderRadius = "0px";
      var element = document.createElement("div");
      element.classList.add("text-xs");
      element.classList.add("price-field");
      element.classList.add("font-Raleway-Light");

      if (arg.event.extendedProps.statut === "disponible") {
        arg.el.style.backgroundColor = "#DE001A";
        if (arg.event.extendedProps.prixBarre === 0) {
          element.appendChild(
            document.createTextNode(
              `${Math.round(
                parseInt(arg.event.extendedProps.price)
              )}€ / personne`
            )
          );
        } else {
          let s = document.createElement("s");
          const price = document.createTextNode(
            `${Math.round(parseInt(arg.event.extendedProps.prixBarre) / 100)}`
          );
          s.appendChild(price);
          const newPrice = document.createTextNode(
            `-${Math.round(parseInt(arg.event.extendedProps.price))}`
          );
          const priceEndText = document.createTextNode("€ / personne");
          element.append(s, newPrice, priceEndText);
        }
      } else if (arg.event.extendedProps.statut === "reserve") {
        arg.el.style.backgroundColor = "#fff";
        arg.el.classList.add("event-reserve");
      } else if (arg.event.extendedProps.statut === "panier") {
        arg.el.style.backgroundColor = "#3eb763";
        arg.el.classList.add("event-panier");
      }

      var date = document.createElement("div");
      date.classList.add("text-xs");
      date.classList.add("date-field");
      date.classList.add("font-Raleway-Light");
      date.classList.add("hidden");

      if (arg.event.extendedProps.statut === "disponible") {
        const start = dayjs(arg.event.start).format("HH[h]mm");
        const end = dayjs(arg.event.end).format("HH[h]mm");

        date.appendChild(document.createTextNode(`de ${start} à ${end}`));
      }

      if (arg.el.children[0].children.length > 0) {
        arg.el.children[0].children[0].appendChild(element);
        arg.el.children[0].children[0].appendChild(date);
      }
    },
    handleMouseEnter(info) {
      if (info.event.extendedProps.statut === "disponible") {
        info.el.classList.add("hover");
        const priceField = info.el.getElementsByClassName("price-field");
        const dateField = info.el.getElementsByClassName("date-field");
        if (priceField.length > 0) priceField[0].classList.add("hidden");
        if (dateField.length > 0) dateField[0].classList.remove("hidden");
      }
    },
    handleMouseLeave(info) {
      info.el.classList.remove("hover");
      const priceField = info.el.getElementsByClassName("price-field");
      const dateField = info.el.getElementsByClassName("date-field");
      if (priceField.length > 0) priceField[0].classList.remove("hidden");
      if (dateField.length > 0) dateField[0].classList.add("hidden");
    },
    handleDayHeader(info) {
      this.intervalCalendar = Object.assign({}, this.intervalCalendar, {
        currentStart: new Date(info.view.currentStart),
        currentEnd: new Date(info.view.currentEnd),
      });

      var date = new Date(info.date);
      var day = document.createElement("div");
      day.classList.add("text-3xl", "date-field", "font-Raleway-Bold");
      day.appendChild(document.createTextNode(date.getDate()));
      info.el.children[0].children[0].appendChild(day);
    },
  },
  watch: {
    timeslots() {
      this.updateUiSlots();
    },
    intervalCalendar(value) {
      this.getSlots(value.currentStart, value.currentEnd);
    },
    getIdSport() {
      this.getSlots();
    },
    getCart() {
      this.getSlots();
    },
    fitArena() {
      this.getSlots();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.planning {
  --fc-event-bg-color: white;
}

.header-font {
  font-size: $doi--header4;
  letter-spacing: 1px;
}
</style>
