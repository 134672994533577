<template>
  <NewModal :open="open" @close-modal="close">
    <template slot="modal_header">
      <DoiText
        :fontSize="4"
        :textColor="1"
        :fontFamily="0"
        class="uppercase ml-2"
        >connexion requise</DoiText
      >
    </template>
    <template slot="modal_content">
      <div class="flex flex-col flex-1">
        <div>
          <DoiText :textColor="1" :fontSize="3" class="text-center"
            >Veuillez vous connecter pour effectuer une réservation.</DoiText
          >
        </div>
        <div class="mt-8 flex flex-1 items-end justify-around">
          <DoiButton
            :bgColor="2"
            :bgHover="3"
            :fontFamily="5"
            class="mx-1"
            link="/signin"
            >CONNEXION</DoiButton
          >
          <DoiButton :bgHover="1" :fontFamily="5" class="mx-1" link="/signup"
            >INSCRIPTION</DoiButton
          >
        </div>
      </div>
    </template>
  </NewModal>
</template>

<script>
import NewModal from "@/components/molecules/NewModal";

export default {
  name: "AuthModal",
  components: { NewModal },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
