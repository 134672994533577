<template>
  <div>
    <div
      :class="['overlay', openSidebar ? 'open' : '']"
      @click="openSidebar = false"
    ></div>
    <div :class="['sidebar', openSidebar ? 'open' : '']">
      <div
        class="flex cursor-pointer items-center justify-center p-8"
        @click="closeAndGo('/')"
      >
        <img
          class="w-52"
          src="@/assets/img/logo-fitarena.png"
          alt="Fit Arena"
        />
      </div>
      <div class="flex flex-col items-start pl-10 pt-0 pb-5">
        <ul v-if="getUserInfo === null" class="flex flex-col link-navigation">
          <li>
            <a
              @click="closeAndGo('/#fit-arena-list')"
              class="cursor-pointer top-menu-link"
              :class="getCurrentRoute === '/' ? 'active' : ''"
              >RÉSERVER</a
            >
          </li>
          <li>
            <a
              @click="closeAndGo('/mon-parcours-dans-la-salle')"
              class="cursor-pointer top-menu-link"
              :class="
                getCurrentRoute === '/mon-parcours-dans-la-salle'
                  ? 'active'
                  : ''
              "
              >MON PARCOURS DANS LA SALLE</a
            >
          </li>
        </ul>
        <ul v-else class="flex flex-col link-navigation">
          <li>
            <a
              @click="closeAndGo('/#fit-arena-list')"
              class="cursor-pointer top-menu-link"
              :class="getCurrentRoute === '/' ? 'active' : ''"
              >RÉSERVER</a
            >
          </li>
          <li>
            <a
              @click="closeAndGo('/profile?q=planning')"
              class="cursor-pointer top-menu-link"
              :class="getCurrentRoute === '/profile?q=planning' ? 'active' : ''"
              >MON PLANNING</a
            >
          </li>
          <li>
            <a
              @click="closeAndGo('/profile?q=reservation')"
              class="cursor-pointer top-menu-link"
              :class="
                getCurrentRoute === '/profile?q=reservation' ? 'active' : ''
              "
              >MES RÉSERVATIONS</a
            >
          </li>
          <li>
            <a
              @click="closeAndGo('/profile?q=classement')"
              class="cursor-pointer top-menu-link"
              :class="
                getCurrentRoute === '/profile?q=classement' ? 'active' : ''
              "
              >MES CLASSEMENTS</a
            >
          </li>
          <li>
            <a
              @click="closeAndGo('/profile?q=performance')"
              class="cursor-pointer top-menu-link"
              :class="
                getCurrentRoute === '/profile?q=performance' ? 'active' : ''
              "
              >MES PERFORMANCES</a
            >
          </li>
          <li>
            <a
              @click="closeAndGo('/profile?q=video')"
              class="cursor-pointer top-menu-link"
              :class="getCurrentRoute === '/profile?q=video' ? 'active' : ''"
              >MES SCORES & REPLAYS</a
            >
          </li>
          <li>
            <a
              @click="closeAndGo('/profile?q=reglements')"
              class="cursor-pointer top-menu-link"
              :class="
                getCurrentRoute === '/profile?q=reglements' ? 'active' : ''
              "
              >MES PAIEMENTS</a
            >
          </li>
          <li>
            <a
              @click="closeAndGo('/profile?q=profile')"
              class="cursor-pointer top-menu-link"
              :class="getCurrentRoute === '/profile?q=profile' ? 'active' : ''"
              >MON COMPTE</a
            >
          </li>
        </ul>
      </div>

      <div class="flex flex-col mx-auto w-3/4" v-if="getUserInfo === null">
        <div class="mb-3">
          <DoiButton @click="closeAndGo('/signin')">CONNEXION</DoiButton>
        </div>
        <div>
          <DoiButton @click="closeAndGo('/signup')" :bgColor="2"
            >INSCRIPTION</DoiButton
          >
        </div>
      </div>
      <div
        v-else
        class="border-t flex justify-center p-2 absolute w-full bottom-0"
      >
        <div class="cursor-pointer" @click="vRemoveToken()">
          <DoiText
            class="underline"
            :fontFamily="6"
            :textColor="1"
            :fontSize="3"
            >Déconnexion</DoiText
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
      openSidebar: false,
      openResponsiveProfile: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("AuthModule", ["is_connected"]),
    getUserInfo() {
      return this.$store.getters["UserModule/get_user_id"];
    },
    getCurrentRoute() {
      return this.$route.path;
    },
  },
  methods: {
    closeAndGo(route) {
      this.openSidebar = false;
      setTimeout(() => {
        this.$router.push(route);
      }, 500);
    },
    vRemoveToken() {
      this.$store.dispatch("AuthModule/DECONNEXION");
      this.$router.push("/signin");
      this.openSidebar = false;
    },
  },
  watch: {
    open(val) {
      const body = document.body;
      this.openSidebar = val;

      if (val) {
        body.classList.add("no-scroll");
      } else {
        body.classList.remove("no-scroll");
        this.$emit("closeSidebar");
      }
    },
    openSidebar(val) {
      const body = document.body;
      if (!val) {
        body.classList.remove("no-scroll");
        this.$emit("closeSidebar");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.link-navigation {
  color: $doi--color-color4;
  font-family: $doi--text-font-family5;
  font-size: $doi--text-font-size3;
}

.sidebar {
  width: 75%;
  background-color: #fff;
  position: fixed;
  height: 100vh;
  z-index: 99999;
  transform: translateX(-300%);
  transition-duration: 0.3s;
  top: 0;
}
.sidebar.open {
  transform: translateX(-128%);
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
  display: none;
}
.overlay.open {
  display: block;
}

.top-menu-link {
  display: block;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

@media (max-width: $doi-breakpoint-mobile) {
  li,
  li > div > p {
    font-size: 0.8rem;
  }
}
</style>
