<template>
  <NewModal
    title="AJOUT CODE RÉDUCTION"
    :open="openDiscountCode"
    @close-modal="$emit('closeModal')"
    size="h-3/5"
  >
    <template #modal_header>
      <DoiText
        :fontSize="4"
        :textColor="1"
        :fontFamily="0"
        class="uppercase ml-2"
        >AJOUT CODE RÉDUCTION</DoiText
      >
    </template>
    <template slot="modal_content">
      <div class="flex flex-col gap-10 px-4">
        <div>
          <DoiText :textColor="1" :fontSize="3" :fontFamily="4" class="mb-2"
            >Saisir un code</DoiText
          >
          <input
            v-model="selectedDiscountCode"
            type="text"
            class="border border-gray-400 rounded-lg py-4 pl-14 focus:outline-none"
          />
        </div>
        <div>
          <DoiText :textColor="1" :fontSize="1" :fontFamily="0"
            >La réduction sera appliquée automatiquement sur le montant de votre
            paiement après que la réservation soit passée.</DoiText
          >
          <DoiText :textColor="1" :fontSize="1" :fontFamily="0" class="my-1"
            >Si le montant total n’est pas consommé, le montant restant sera
            perdu.</DoiText
          >
        </div>
      </div>
    </template>
    <template #modal_footer>
      <DoiButton
        @click="submitCode()"
        class="mx-auto w-min"
        :fontFamily="6"
        :disabled="selectedDiscountCode === ''"
        >VALIDER</DoiButton
      >
    </template>
  </NewModal>
</template>

<script>
import NewModal from "@/components/molecules/NewModal.vue";
import SelectSingle from "@/components/atomic/Select.vue";

export default {
  name: "DiscountCodeModal",
  data() {
    return {
      selectedDiscountCode: "",
    };
  },
  props: {
    openDiscountCode: Boolean,
    discountCode: String,
  },
  components: { NewModal, SelectSingle },
  mounted() {
    this.selectedDiscountCode = this.discountCode;
  },
  methods: {
    submitCode() {
      this.$emit("validateDiscountCode", this.selectedDiscountCode);
      this.selectedDiscountCode = "";
    },
  },
};
</script>

<style scoped>
.doi-input {
  width: 100%;
  padding: 10px;
  border: solid 0.13rem rgb(225, 225, 225);
  background-color: transparent;
  border-radius: 10px;
}

.doi-input:focus {
  outline: none;
}
</style>
